.primary-color-text {
  color: #F44336 !important; }

.dark-primary-color-text {
  color: #D32F2F !important; }

.light-primary-color-text {
  color: #FFCDD2 !important; }

.accent-color-text {
  color: #536DFE !important; }

.primary-color {
  background-color: #F44336 !important; }

.dark-primary-color {
  background-color: #D32F2F !important; }

.light-primary-color {
  background-color: #FFCDD2 !important; }

.accent-color {
  background-color: #536DFE !important; }

body {
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  -ms-flex-direction: column;
  flex-direction: column; }

main {
  -ms-flex: 1 0 auto;
  flex: 1 0 auto; }

body, .footer-simple {
  background-color: #EFEFEF; }

body, button, input, select, textarea {
  text-rendering: optimizeLegibility;
  font-feature-settings: "liga", "kern";
  font-kerning: normal;
  font-size: 14px; }

.select2 {
  width: 100% !important; }

.select2-selection:focus, .selection:focus {
  outline: none !important; }

.select2-container:focus {
  outline: none !important; }

.select2-container .select2-selection--single {
  height: 40px !important; }

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 39px !important; }

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 39px !important; }

.select2-container--default .select2-search--dropdown .select2-search__field {
  margin: 0 0 5px 0;
  height: 2.5rem; }

.slider .slides {
  background-color: #FAFAFA; }

.slider .indicators .indicator-item.active {
  background-color: #039BE5; }

pre[class*="language-"] {
  padding: 10px 0px 0px 12px !important; }

a:hover {
  cursor: pointer; }
  a:hover.btn-flat {
    text-decoration: none; }

.btn-full {
  width: 100%; }

.slide h1, .slide h2, .slide h3, .slide h4, .slide h5, .slide h6 {
  margin-top: 40px;
  margin-bottom: 14.5px; }

.section-help-header h1 {
  margin-top: 20px; }

h1 {
  font-family: inherit;
  font-weight: 100;
  line-height: 1.42;
  color: rgba(0, 0, 0, 0.54);
  font-size: 3rem;
  margin: 2.1rem 0 0.68rem 0;
  margin-top: 0; }

h2 {
  font-size: 36px;
  line-height: 1.42;
  font-weight: 300; }

h3 {
  font-size: 36px;
  line-height: 1.42; }

h4 {
  font-size: 22px;
  font-weight: 400;
  margin-top: 0; }

h6 {
  font-size: 1.4rem; }

/*----- LOGO ------*/
.logo-img {
  width: 170px; }

.logo-scroll {
  vertical-align: middle;
  width: 0;
  transition: all .5s;
  overflow: hidden; }
  .logo-scroll img {
    width: 25px;
    max-width: none;
    vertical-align: middle; }

.sticky-active .logo-scroll {
  width: 40px; }

.nav-back-button {
  position: absolute;
  left: 40px;
  top: 10px;
  font-size: 24px;
  transition: all 200ms ease; }

/*----- NAVBAR-SIDEBAR-MENU ------*/
.accent-bar {
  height: 20px; }

nav ul a {
  color: #FFCDD2;
  text-transform: uppercase; }
  nav ul a:hover {
    color: #FFF; }

nav .nama {
  width: 185px !important; }

.nama {
  width: 185px !important; }

nav .truncate {
  float: left; }

nav .brand-logo {
  width: 170px; }

nav.nav-main ul a {
  color: #757575;
  font-weight: 700; }
  nav.nav-main ul a:hover {
    color: #D32F2F; }

nav ul li:hover, nav ul#nav-mobile a:hover {
  background-color: transparent;
  color: #D32F2F; }

nav.nav-main ul a:hover {
  background-color: transparent; }

.side-nav .collapsible-body li.active, .side-nav.fixed .collapsible-body li.active, .side-nav .collapsible-body {
  background-color: transparent; }

nav#menu {
  box-shadow: none; }

nav .dropdown-content {
  top: 64px !important; }

.dropdown-content li > a {
  font-size: 14px;
  color: #000; }

.dropdown-content li a:hover {
  text-decoration: none; }

.dropdown-button .truncate {
  max-width: 150px; }

.secondary-navbar {
  width: 100%;
  z-index: 3;
  height: 58px; }
  .secondary-navbar span {
    display: block;
    position: relative;
    height: 58px;
    line-height: 56px; }
    .secondary-navbar span.title {
      font-size: 18px; }

.card-heading h2 {
  font-size: 18px; }

.secondary-navbar.nav-condensed {
  height: 45px !important;
  line-height: 45px !important; }
  .secondary-navbar.nav-condensed span, .secondary-navbar.nav-condensed li .secondary-navbar.nav-condensed .breadcrumb {
    height: 45px !important;
    line-height: 45px !important; }
  .secondary-navbar.nav-condensed .breadcrumb li {
    height: 45px !important;
    line-height: 45px !important; }
    .secondary-navbar.nav-condensed .breadcrumb li .material-icons {
      height: 45px !important;
      line-height: 45px !important; }

#left-sidebar-nav .side-nav {
  left: 0;
  position: relative;
  box-shadow: none;
  width: 100%;
  z-index: 998;
  margin-top: 20px;
  overflow-y: hidden; }

ul.side-nav li.user-details {
  background: url(../../images/bg/bg.png) center center no-repeat;
  background-size: cover;
  margin-bottom: 15px;
  padding: 30px 0 10px 5px; }

.profile-btn {
  min-width: 100%;
  margin-top: 5px;
  text-transform: capitalize;
  padding: 0;
  font-size: 19px; }

.side-nav li.active {
  background-color: inherit; }

.side-nav li i {
  line-height: inherit;
  display: block;
  float: left;
  text-align: center;
  margin-right: 1rem; }

a.button-collapse.top-nav {
  position: absolute;
  left: 7.5%;
  top: 0;
  float: none;
  color: #fff;
  font-size: 32px;
  z-index: 2; }
  a.button-collapse.top-nav.full {
    line-height: 122px; }

nav.top-nav a.page-title {
  line-height: 122px;
  font-size: 48px; }

.table-of-contents {
  margin-top: 60px; }

.collapsible-header i {
  width: 1.7rem; }

.side-nav li.active {
  font-weight: 700; }

ul.side-nav.fixed li.active a {
  font-weight: 700; }

ul.side-nav li a {
  font-size: 14px;
  line-height: 55px;
  height: 55px; }

.side-nav .collapsible-header {
  font-size: 14px;
  line-height: 55px;
  height: 55px; }

.side-nav .collapsible li.active .collapsible-body li a {
  font-weight: 400; }

ul.side-nav.fixed:hover, ul.side-nav:hover {
  overflow-y: auto; }

.side-nav li:hover {
  background-color: rgba(142, 142, 142, 0.05); }

.breadcrumb, .details h2, .mini-head {
  margin: 0 !important; }

.breadcrumb h2 {
  padding: 19px 0; }

.breadcrumb li {
  display: block;
  float: left; }

.mini-head li {
  display: block;
  float: left; }

.breadcrumb li a {
  font-size: 14px;
  padding: 0 18px;
  display: block;
  color: #e1f5fe; }
  .breadcrumb li a:hover {
    color: #FFF; }

.breadcrumb.icon li a {
  padding: 0 15px; }

.breadcrumb li.selected .material-icons {
  color: #b0bec5; }

.breadcrumb li a.current {
  font-weight: 600; }

.breadcrumb li .material-icons {
  line-height: 58px; }

.mini-head li {
  line-height: 30px; }

.collaps .collapsible-header, .collapse .collapsible-body {
  border-bottom: 0; }

/*---- FOOTER ----*/
footer .mega-footer {
  padding-top: 60px;
  padding-bottom: 60px; }

.footer-copyright {
  padding: 30px 0;
  font-size: 13px; }
  .footer-copyright a:hover {
    color: #F44336;
    text-decoration: none; }

.footer-simple a {
  color: grey !important; }

.footer-copyright a {
  color: #6F6F6F;
  font-size: 14px; }

footer {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  footer li {
    font-size: 13px; }
    footer li a {
      color: #6F6F6F;
      font-size: 14px; }
  footer p {
    font-size: 13px; }

/*ALERT INDICATOR*/
.error li {
  list-style: none; }

.alert-info {
  font-size: 12px;
  background-color: #CBEFFF;
  padding: 10px;
  margin-top: 20px; }

.error {
  font-size: 13px;
  color: #e53935;
  margin-bottom: 10px; }

.info-bar {
  background-color: #f9f9f9;
  border-bottom: 1px solid #e5e5e5; }

.loading {
  bottom: 17px;
  right: 25px;
  position: absolute; }

.front-loading {
  position: absolute;
  right: 20px;
  bottom: 17px; }

/*---HELPER---*/
.caption {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54); }
  .caption.sub-head {
    font-size: 16px;
    font-weight: 300;
    margin-top: 16px; }

.slide p.caption {
  font-size: 18px;
  margin-bottom: 50px; }

.bg-light.lt {
  background-color: #f8f8f8 !important; }

.b-t {
  border-top: 1px solid #dddee0; }

.b-b {
  border-bottom: 1px solid #dddee0; }

.b-light {
  border-color: #eaebec; }

.p {
  padding: 16px; }

.p-t {
  padding-top: 40px; }

.no-padding {
  padding: 0; }
  .no-padding td {
    padding: 0; }

.text-muted {
  color: inherit;
  opacity: .75; }

.no-m {
  margin: 0; }

.no-m-b {
  margin-bottom: 0; }

.no-m-t {
  margin-top: 0; }

.no-m-l {
  margin-left: 0 !important; }

.text-shadow {
  text-shadow: 0 4px 0 rgba(0, 0, 0, 0.1); }

.text-light-shadow {
  text-shadow: 0 0 20px rgba(255, 255, 255, 0.6); }

.collapse {
  display: none !important; }

.is-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10; }

.l-t {
  text-decoration: line-through; }

.hero {
  height: calc(100vh - 109px);
  position: relative;
  background-color: #FAFAFA;
  padding-top: 20px; }

.hero-overlay {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.45); }

.hero-content {
  position: relative;
  top: 50%;
  transform: translateY(-50%); }

.capli li {
  font-size: 17px; }

.material-icons {
  text-rendering: optimizeLegibility; }
  .material-icons.md-18.left {
    font-size: 18px !important; }
  .material-icons.md-24 {
    font-size: 24px; }
  .material-icons.md-36 {
    font-size: 36px; }
  .material-icons.md-48 {
    font-size: 48px; }
  .material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54); }
    .material-icons.md-dark.md-inactive {
      color: rgba(0, 0, 0, 0.26); }
  .material-icons.md-light {
    color: white; }
    .material-icons.md-light.md-inactive {
      color: rgba(255, 255, 255, 0.3); }

.tabs .tab a {
  color: #F44336; }
  .tabs .tab a:hover {
    color: #D32F2F; }

.tabs .indicator {
  background-color: #F44336; }

.collapsible .collection-header {
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 20px; }

.popout .active .collapsible-header .notif-title {
  display: none; }

.collection a.collection-item:not(.active):hover {
  background-color: #F5F5F5; }

.slider .slides li img {
  background-position: center; }

/*Card-Panel*/
.head-card {
  font-size: 20px; }

.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  border-radius: 3px; }

.panel-default {
  border-color: #dddee0; }

.panel-card {
  border-width: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15); }

.panel-heading {
  padding: 16px 24px;
  border-radius: 3px 3px 0 0;
  border-bottom: 1px solid #eaebec;
  font-size: 16px; }

.panel-default > .panel-heading {
  color: #333;
  background-color: #f5f5f5; }

.card-board {
  position: inherit;
  margin-top: -70px; }

.board-content {
  display: block;
  position: relative;
  overflow: hidden;
  padding-bottom: 20px; }

.board-head {
  height: 70px;
  line-height: 70px;
  font-size: 24px;
  color: #627E8C; }

.card-board-head, .profile {
  height: 71px; }

.card-board-head .tabs {
  height: 70px; }
  .card-board-head .tabs .tab {
    height: 70px;
    line-height: 70px; }

.card-heading {
  position: relative;
  padding: 20px 24px; }
  .card-heading small {
    display: block;
    margin-top: 8px;
    opacity: .5; }

.card-body {
  padding: 20px 24px; }

.panel-default > .panel-footer, .panel-default > .panel-heading {
  border-color: #eaebec; }

.card-footer {
  background: #f9f9f9;
  border-top: 1px #d9d9d9 solid; }
  .card-footer a {
    color: #606060; }

.site-map a {
  color: #606060; }

.card-footer a:hover {
  color: #000; }

.card .card-action.dark a {
  color: #9C9C9C; }
  .card .card-action.dark a:hover {
    color: #F44336; }

/*---- PAGE ----*/
.one-page {
  min-height: 500px; }
  .one-page .btn-next {
    z-index: 5;
    position: absolute; }
  .one-page .container .btn-next {
    bottom: -30px; }
  .one-page .btn-next-center {
    left: 50%;
    bottom: -25px;
    transform: translate3d(-50%, 0, 0); }

.slide {
  position: relative;
  width: 100%;
  border-bottom: 1px solid #f0f0f0;
  padding: 50px 0; }
  .slide .list-vertical li {
    border-bottom: 1px solid #f0f0f0;
    padding: 25px 0 20px; }
    .slide .list-vertical li .list-icon {
      float: left;
      font-size: 40px;
      line-height: 1; }
    .slide .list-vertical li .list-info {
      margin-left: 60px; }
      .slide .list-vertical li .list-info span {
        color: rgba(0, 0, 0, 0.6); }

.slides .author {
  font-weight: 700;
  font-style: italic;
  font-size: 16px; }

.list-info h4 {
  font-size: 22px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 400; }

main.page, .help-content, .login-page, .ignite-cta, .slide.help-center, .product-content {
  background-color: #F7F7F7; }

/*---- Small Screen -----*/
@media only screen and (max-width: 992px) {
  footer.page, header .page, main.page {
    padding-left: 0; }
  nav .nav-wrapper a.page-title {
    font-size: 36px; }
  .container {
    padding: 0;
    width: 93%; }
  footer {
    text-align: center; }
  .bank {
    width: 90%; }
  .hero {
    height: auto; }
  .slide {
    padding: 10px 0;
    padding-top: 30px; }
  .p-t {
    padding-top: 20px; }
  .side-nav a {
    height: 40px;
    line-height: 40px;
    text-transform: none;
    font-size: 13px;
    padding: 0; }
  .card-help .panel-heading span {
    float: left;
    margin-top: 25px; }
  .user-details {
    margin: 0;
    height: 114px;
    background: url(../../images/bg/bg.png) center center no-repeat;
    background-size: cover;
    margin-bottom: 20px; }
  #partner-hero {
    background: none !important; }
  .notif-btn {
    bottom: 45px;
    right: 24px; }
  .app-tab-icon {
    line-height: 60px;
    height: 50px; }
  .app-tab {
    display: block;
    font-size: 12px;
    line-height: 15px; }
  .card-board-head .tabs .tab {
    line-height: 45px; } }

/*---- Table Screen -----*/
@media only screen and (min-width: 768px) {
  .app-tab-icon {
    line-height: 40px; } }

/*---- Large Screen -----*/
@media only screen and (min-width: 993px) {
  .is-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10; }
  .sticky-margin {
    margin-top: 64px; }
  .swag {
    background-size: cover; }
  .sub-help, footer.page, header .page, main.page {
    padding-left: 240px; }
  .container {
    width: 95%; }
    .container.default {
      width: 80%; }
  .cont-store {
    width: 60%; }
  .cart-btn-float {
    position: absolute;
    right: 30px;
    top: 20px; }
  .side-nav li {
    padding: 0;
    width: 100%; }
  .side-nav .collapsible-header {
    margin: 0; }
  .side-help {
    left: 0 !important; }
  .notif-btn {
    bottom: 0;
    right: 24px;
    top: 140px;
    position: absolute; } }

.user-name, .user-email {
  font-size: 13px;
  line-height: 30px; }

.user-name {
  font-size: 17px;
  padding-top: 40px; }
  .user-name a {
    font-size: 17px !important;
    font-size: 13px;
    padding: 0; }

.transaksi form {
  margin-bottom: 20px; }

.webapp nav h4 {
  font-size: 22px;
  font-weight: 400;
  opacity: .8;
  line-height: 33px; }

#notifikasi-content .collection .collection-item.avatar {
  height: auto;
  min-height: 50px; }

#notif-list li:hover .collapsible-header {
  background-color: #efefef;
  cursor: pointer; }

.collapsible.popout > li:hover.active {
  background-color: #FFF; }

.collection .collection-item.active {
  background-color: #efefef;
  color: #606060; }

.report-table {
  border: 1px solid; }
  .report-table > tbody {
    font-size: 10px; }
  .report-table td, .report-table th {
    border: 1px solid; }

.step {
  font-weight: 500; }
  .step i {
    font-size: 20px; }
  .step li {
    color: #AAA; }
    .step li.active {
      color: #000; }
  .step small {
    font-weight: 400;
    font-style: italic; }

/*Custom*/
#index-banner-shop {
  background: url(../../images/cover/bg4.jpg) center/cover no-repeat #F0F0F0; }

#servicesSlider {
  margin-top: 8px; }

#download {
  margin-top: 50px; }

#pulsa-hero {
  background-color: #FAFAFA; }

#tagihan-hero {
  background: linear-gradient(rgba(119, 118, 118, 0.12), rgba(0, 0, 0, 0.4)), url("../../images/cover/hero2.png") bottom center/cover no-repeat; }

#why-train {
  background: linear-gradient(rgba(101, 101, 101, 0.38), rgba(0, 0, 0, 0.5)), url("../../images/cover/train-seat.jpg") center center;
  background-size: cover; }

#partner-hero {
  background: url("https://ssl.gstatic.com/partners/images/home/sg/business_02.png") center/cover #CCC; }

#login {
  position: relative;
  min-height: 40rem;
  background-position: center center;
  background-size: cover; }

.link-col {
  margin: 10px; }

.beta {
  position: relative;
  float: right;
  padding: 0;
  font-size: 9px;
  margin-left: 2px;
  margin-top: -5px;
  color: #0d47a1; }

.total-fee {
  font-size: 25px;
  font-weight: 500; }

.report-bar {
  margin-top: 40px; }

.get-the-app a {
  display: inline-block;
  width: 143px;
  height: 42px;
  overflow: hidden;
  text-indent: -999px;
  border-radius: 3px;
  border: 1px solid #000; }

.get-the-app ul {
  list-style-type: none;
  overflow: hidden; }

.get-the-app li {
  float: left;
  margin-right: 10px; }

.get-the-app a.app-store {
  background: url(../../images/logo/appstore-icon.png) -1px -1px no-repeat #000; }

.get-the-app a.google-play {
  background: url(../../images/logo/appstore-icon.png) -155px -1px no-repeat #000; }

.download a {
  display: inline-block; }

.app-store-button {
  background-image: url(../../images/logo/download-button.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  width: 170px;
  height: 60px;
  float: left;
  margin-right: 11px; }

.google-play-button {
  background-image: url(../../images/logo/download-button.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 -62px;
  width: 170px;
  height: 60px;
  float: left;
  margin-right: 11px; }

.bank {
  width: 75%; }

.fbimage {
  background-image: url(../../images/logo/sprites-social.png);
  background-repeat: no-repeat;
  background-position: -48px 0;
  width: 48px;
  height: 48px;
  float: left;
  margin-right: 11px; }
  .fbimage:hover {
    background-position: -48px -48px; }

.twitterimage {
  background-image: url(../../images/logo/sprites-social.png);
  background-repeat: no-repeat;
  background-position: 0px 0px;
  width: 48px;
  height: 48px;
  float: left;
  margin-right: 11px; }
  .twitterimage:hover {
    background-position: 0px -48px; }

.googleplusimage {
  background-image: url(../../images/logo/sprites-social.png);
  background-repeat: no-repeat;
  background-position: -96px 0;
  width: 48px;
  height: 48px;
  float: left;
  margin-right: 11px; }
  .googleplusimage:hover {
    background-position: -96px -48px; }

.cd-top {
  display: inline-block;
  height: 40px;
  width: 40px;
  position: fixed;
  bottom: 40px;
  right: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  /* image replacement properties */
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  background: rgba(109, 161, 218, 0.8) url(../../images/vec/cd-top-arrow.svg) no-repeat center 50%;
  visibility: hidden;
  opacity: 0;
  transition: opacity .3s 0s, visibility 0s .3s; }
  .cd-top.cd-is-visible, .cd-top.cd-fade-out {
    transition: opacity .3s 0s, visibility 0s 0s; }

.no-touch .cd-top:hover {
  transition: opacity .3s 0s, visibility 0s 0s; }

.cd-top.cd-is-visible {
  /* the button becomes visible */
  visibility: visible;
  opacity: 1; }

.cd-top.cd-fade-out {
  /* if the user keeps scrolling down, the button is out of focus and becomes less visible */
  opacity: .5; }

.no-touch .cd-top:hover {
  background-color: #56A3E8;
  opacity: 1; }

.pagination li.active {
  background-color: #F44336;
  color: #fff; }

table.tablesorter thead tr .tablesorter-headerAsc .tablesorter-header-inner {
  background: url(https://cdnjs.cloudflare.com/ajax/libs/jquery.tablesorter/2.23.4/css/images/black-asc.gif) center right no-repeat; }

table.tablesorter thead tr .tablesorter-headerDesc .tablesorter-header-inner {
  background: url(https://cdnjs.cloudflare.com/ajax/libs/jquery.tablesorter/2.23.4/css/images/black-desc.gif) center right no-repeat; }

table.tablesorter thead tr .tablesorter-headerUnSorted .tablesorter-header-inner {
  background: url(https://cdnjs.cloudflare.com/ajax/libs/jquery.tablesorter/2.23.4/css/images/black-unsorted.gif) center right no-repeat; }

table#scheduleDataTable th:last-child .tablesorter-header-inner {
  background: none; }

table.condensed td {
  padding: 3px; }

@media only screen and (min-width: 768px) {
  .cd-top {
    right: 20px;
    bottom: 20px; } }

@media only screen and (min-width: 1024px) {
  .cd-top {
    height: 60px;
    width: 60px;
    right: 30px;
    bottom: 30px; } }

/*Shop*/
.cart-menu {
  background-color: #F44336; }
  .cart-menu a {
    color: #FFF !important;
    height: 64px; }
  .cart-menu:hover {
    background-color: #D32F2F; }
  .cart-menu span {
    font-size: 11px;
    font-weight: 300; }
  .cart-menu i {
    margin-top: 20px; }

.search-box {
  border: 1px solid #E2E2E2;
  font-size: 16px;
  padding: 10px 16px;
  background-color: #FBF9F9;
  color: initial; }

/*# sourceMappingURL=default.css.map */
